<script>
export default {
	props: {
		value: Array,
		newLabel: {
			type: String,
			default: 'Add Clause',
		},
		newLabelForAIGeneration: {
			type: String,
			default: 'Add Clause Using AI',
		},
		label: String,
		testid:String,
		title: String,
		disabled:Boolean
	},
};
</script>

<template>
	<div class="py-2">
		<h5 class="add-clause-heading">{{title}}</h5>
		<list-item
			v-slot="{ item: clause, index: index }"
			v-model="value"
			:new-label="newLabel"
			:new-label-for-AI-generation="newLabelForAIGeneration"
			:object-prototype="
				() => {
					return {};
				}
			"
			:testid="testid"
			:disabled="disabled"
		>
			<b-form-textarea
				v-model="clause.text"
				placeholder="Add a custom clause"
				rows="2"
				max-rows="4"
				size="sm"
				style="width: 90%"
				no-resize
				trim
				:data-testid="testid + index"
				class="custom-scroll scroll-small text-area-scroll additional-clause-text"
				:disabled="disabled"
			/>
		</list-item>
	</div>
</template>
